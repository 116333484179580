<template >
<div>
    <Loading v-if="loading"></Loading>
    <div v-if="!loading">
        <!-- ======= Breadcrumbs ======= -->
                  <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a >Our Work</a></li>
          
        </ol>
        <h2>Our Work</h2>

      </div>
    </section>


    <section id="services" class="services">
      <!-- Tab panes -->

      <div class="container" data-aos="fade-up">
      
            <div class="row gy-4">
              <div
                v-for="(project, index) in projects"
                :key="index"
                class="col-lg-4 col-md-4"
                @click="routeTo('project',project)"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div
                  style="cursor: pointer;"
                  class="service-box bridegs full-card"
                  @mouseenter="projects[index].hover = true"
                  @mouseleave="projects[index].hover = false"
                  :style="{
                    'border-bottom': '3px solid #' + project.theme,
                    background: project.hover ? '#' + project.theme : 'white',
                  }"
                >
                  <img :src="project.logo_image" alt="" width="30%" />
                  
                  <h3 style="padding-top:10px">{{ project.header }}</h3>
                  <p class="colapsed-five-line-text" v-html="project.details"></p>
                  
                </div>
              </div>
            </div>

            <br />
        
          
       
      </div>
    </section>
    <!-- End Services Section -->
  </div>
</div>

</template>
<script>
import { getFields } from "../assets/js/service";
import Loading from './container/Loading.vue'
export default { 
   metaInfo: {
    // Children can override the title.
    title: 'Our Work',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Our Works"},
      {property:"og:title" ,content:"First Consult Our Works"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/our_work"},


      {name:"twitter:title" ,content:"First Consult Our Works"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/our_work"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  components:{
Loading
  },
  data() {
    return {
      sectors: null,
      projects: null,loading:false,
    };
  },
  mounted() {
    let token = localStorage.getItem("token");this.$store.commit('set',['loading',true]);
  
    getFields("Projects", token,false,'components').then((resp) => {
      this.projects = resp.data.map((project) => ({
        ...project,
        hover: false,
      }));
      this.$store.commit('set',['loading',false]);
    });
  }, watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
     }
    },
  methods: {
    routeTo(route,projectData) {
      if(route == 'project'){
        this.$store.commit("set", ["projectInformation", projectData]);
        this.$router.push('main/'+projectData.id);
      }else{
        this.$router.push(route);
      }
      
    },
  },
};
</script>
<style>
.bridges-components-headers {
  font-weight: 600;
  color: #0b198f;
}
.services .service-box.bridegs {
  border-bottom: 3px solid rgb(22, 20, 67);
}

.services .service-box.bridegs:hover {
  background: rgb(22, 20, 67);
}
</style>